#home {
  background-image: url("../../images/cover.jpg");
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  color: white;
  font-family: Arial, sans-serif;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.navbar .navbar-collapse {
  display: flex;
  justify-content: flex-end;
}

header {
  margin: 0;
  font-weight: bolder;
  font-size: 50px;
  color: #dfdbd8;
}

p {
  font-size: 18px;
}

@media (max-width: 768px) {
  header {
    font-size: 30px;
  }
  p {
    font-size: 16px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 18px;
  }
}
.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.home-content {
  text-align: center;
  /* margin-bottom: 20px; */
  position: relative;
  z-index: 1;
}

.home-subtitle {
  font-weight: normal;
  margin-bottom: 20px;
  color: #ffffff;
}

.home-button {
  background-color: #ff7f00;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.home-button svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.about-header {
  color: #0c0c0d;
  text-align: center;
  font-size: 45px !important;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 700;
}

.about-image {
  max-width: 100%;
  border-radius: 20px;
}

.services-header {
  color: #0c0c0d;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 700;
}

.header-text {
  color: #0c0c0d;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 700;
}
.lordIcon {
  width: 100px !important;
  height: 100px !important;
}

.about-section {
  padding: 100px 0;
}

.about-heading {
  color: #0c0c0d;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
}

.stats-container {
  background: radial-gradient(
    circle,
    rgba(183, 182, 183, 1) 0%,
    rgba(255, 255, 255, 1) 88%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 40px 0;
}

.stats-container .display-4 {
  font-size: 2.5rem;
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.services-2 {
  padding-top: 30px;
  border-radius: 20px;
  background: rgb(183, 182, 183);
  background: radial-gradient(
    circle,
    rgba(183, 182, 183, 1) 0%,
    rgba(255, 255, 255, 1) 88%,
    rgba(255, 255, 255, 1) 100%
  );
}

/*about us*/

.btn2 {
  width: 185px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  border: none;
  transition: all 0.5s ease-in-out;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: #706464;
  color: #ffffff;
}

.btn2:hover {
  box-shadow: 0 0 20px 0px #2e2e2e3a;
}

.btn2 .icon2 {
  position: absolute;
  height: 40px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.btn2 .text2 {
  transform: translateX(55px);
}

.btn2:hover .icon2 {
  width: 185px;
}

.btn2:hover .text2 {
  transition: all 0.5s;
  opacity: 0;
}

.btn2:focus {
  outline: none;
}

.btn2:active .icon2 {
  transform: scale(0.85);
}

.btn-link2 {
  text-decoration: none !important;
  color: #ffffff !important;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
  }
  .btn2 {
    width: 150px;
    height: 40px;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
  .btn2 .icon2 {
    height: 30px;
    width: 60px;
  }
  .btn2 .text2 {
    transform: translateX(40px);
  }

  .container h1 {
    font-size: 23px;
  }

  .container p {
    font-size: 16px;
  }

  .container header {
    font-size: 23px;
  }
}

/*end about us*/
/*service*/
.box-section2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1200px;
}

.box-example2 {
  flex: 0 0 calc(33.33% - 20px);
  margin: 0 10px 20px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
}

.box-content2 {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}

.box-example2 img {
  width: 250px;
  height: auto;
  max-width: 100%;
  border-radius: 12px;
}

.box-example2 h2 {
  margin-top: 10px;
  font-size: 23px;
  color: #333;
  font-weight: bold;
}

@media (max-width: 768px) {
  .box-section2 {
    justify-content: center;
  }

  .box-example2 h2 {
    font-size: 18px;
  }
  .box-example2 p {
    font-size: 16px;
  }
  .box-example2 {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 0 10px 20px;
  }
}

/*end service*/
/*why us*/
.box-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1200px;
}

.header-text {
  color: #0c0c0d;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: 700;
}

.box-example {
  flex: 0 0 calc(33.33% - 20px);
  margin: 0 10px 20px;
  border-radius: 25px;
  border: 3px solid #706464;
  overflow: hidden;
}
.box-example h1 {
  font-size: 23px;
}

.box-content {
  padding: 20px;
  text-align: center;
}

.box-example img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 200px;
  border-radius: 12px;
}

.box-example h2 {
  margin-top: 10px;
  font-size: 23px;
  color: #333;
}

@media (max-width: 768px) {
  .box-section {
    justify-content: center;
  }

  .box-example {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 0 10px 20px;
  }
}
/*end of why us*/
