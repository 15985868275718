:root {
  --first-color: #2c3e50;
  --first-color-light: #bdc3c7;
  --first-color-hover: #34495e;
}

.signup-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, var(--first-color), #34495e);
  padding: 2rem 1rem;
}
.signin-link {
  color: var(--first-color);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signin-link:hover {
  color: var(--first-color-hover);
}
/* Animation for form submission */
@keyframes submitPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.custom-button:active:not(:disabled) {
  animation: submitPulse 0.3s ease;
}
