.consultations-container {
  padding: 1rem;
}

.consultation-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.consultation-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-box {
  max-height: 100px;
  overflow-y: auto;
  font-size: 0.9rem;
  color: #666;
}

.form-check-input:checked {
  background-color: #28a745;
  border-color: #28a745;
}

.consultation-card .btn-outline-primary {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
}