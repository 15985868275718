.timeshare-area {
  padding: 1rem;
}

.timeshare-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.timeshare-card {
  border: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.timeshare-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pipeline-badge {
  font-size: 0.8rem;
  padding: 0.35em 0.65em;
  font-weight: 500;
}

.description-box {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  max-height: 100px;
  overflow-y: auto;
  color: #666;
}

.progress {
  border-radius: 1rem;
  background-color: #e9ecef;
}

.progress-bar {
  border-radius: 1rem;
  transition: width 0.6s ease;
}
