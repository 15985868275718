/* Sidebar Styles */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Update these CSS variables at the :root */
:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #2c3e50;
  --first-color-light: #bdc3c7;
  --first-color-hover: #34495e;
  --white-color: #f7f6fb;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}
#body-pd {
  position: relative;
  margin: var(--header-height) 0 0 0;
  /* padding: 1rem; */
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}
.body-pd {
  padding-left: calc(var(--nav-width));
}
@media screen and (min-width: 768px) {
  /* #body-pd {
    margin: calc(var(--header-height) + 2rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  } */

  .body-pd {
    padding-left: calc(var(--nav-width) + 80px);
  }
}

.dashboard {
  /* Update/Add these styles */
  .l-navbar {
    background: linear-gradient(to bottom, var(--first-color), #34495e);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .nav_link {
    position: relative;
    color: var(--first-color-light);
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
  }

  .nav_link:hover {
    color: var(--white-color);
    background-color: var(--first-color-hover);
    padding-left: 1.5rem;
  }

  .nav_icon {
    font-size: 1.25rem;
    transition: 0.3s;
  }

  .nav_link:hover .nav_icon {
    transform: translateX(5px);
  }

  .active {
    color: var(--white-color);
    background-color: var(--first-color-hover);
  }

  .active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: var(--white-color);
    border-radius: 0 5px 5px 0;
  }

  .nav_logo {
    transition: all 0.3s ease;
    padding: 1rem;
  }

  .nav_logo:hover {
    transform: scale(1.05);
  }

  .company-logo {
    filter: brightness(0) invert(1);
    transition: all 0.3s ease;
  }

  .nav_logo:hover .company-logo {
    transform: rotate(5deg);
  }

  .nav_logo-name {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: 0.5s;
  }
  .body-pd-header {
    padding-left: calc(var(--nav-width));
  }
  .header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }

  .header_img img {
    width: 40px;
  }

  .l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--first-color);
    padding: 0.5rem 1rem 0 0;
    transition: 0.5s;
    z-index: var(--z-fixed);
  }

  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .nav_logo,
  .nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
  }

  .nav_logo {
    margin-bottom: 2rem;
  }

  .nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
  }

  .nav_logo-name {
    color: var(--white-color);
    font-weight: 700;
  }
  .show {
    left: 0;
  }

  .active {
    color: var(--white-color);
  }
  .active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
  }
  .height-100 {
    height: 100vh;
  }
  @media screen and (min-width: 768px) {
    .header {
      height: calc(var(--header-height) + 1rem);
      padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    }
    .header_img {
      width: 40px;
      height: 40px;
    }
    .header_img img {
      width: 45px;
    }
    .l-navbar {
      left: 0;
      padding: 1rem 1rem 0 0;
    }
    .show {
      width: calc(var(--nav-width) + 156px);
    }
    .body-pd-header {
      padding-left: calc(var(--nav-width) + 188px);
    }
  }
  /* .compose-button {
    position: fixed;
    top: 80px;
    right: 32px;
    background: #3b82f6;
    color: white;
    border-radius: 30px;
    padding: 16px 32px;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
    border: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    z-index: var(--z-fixed);
  }

  .compose-button:hover {
    background: #2563eb;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(59, 130, 246, 0.4);
  } */
  /*
  .content-card {
    margin-top: 60px;
  }
     */

  .contact-card {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: none;
    background: #ffffff;
  }

  .contact-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
  }

  .contact-card .card-body {
    padding: 1.5rem;
  }

  .contact-card h6 {
    color: var(--first-color);
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  .contact-card .badge {
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .contact-card .location-info {
    color: #6c757d;
    font-size: 0.875rem;
  }

  .contact-card .btn {
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease;
  }

  .contact-card .btn:hover {
    transform: translateY(-1px);
  }

  .contact-card .btn-outline-primary {
    border-color: var(--first-color);
    color: var(--first-color);
  }

  .contact-card .btn-outline-primary:hover {
    background-color: var(--first-color);
    color: white;
  }

  .contact-card .btn-outline-success:hover {
    background-color: #28a745;
    border-color: #28a745;
  }

  /* Pagination Styles */
  .pagination {
    margin-top: 2rem;
  }

  .page-item .page-link {
    color: var(--first-color);
    border: 1px solid #dee2e6;
    margin: 0 2px;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    font-weight: 500;
    transition: all 0.2s ease;
  }

  .page-item.active .page-link {
    background-color: var(--first-color);
    border-color: var(--first-color);
  }

  .page-item .page-link:hover {
    background-color: var(--first-color-light);
    border-color: var(--first-color-light);
    color: var(--first-color);
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
  }

  /* Loading Spinner */
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 0.2em;
    color: var(--first-color);
  }
}
