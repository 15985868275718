:root {
  --first-color: #2c3e50;
  --first-color-light: #bdc3c7;
  --first-color-hover: #34495e;
}

.bg-gradient {
  background: linear-gradient(135deg, var(--first-color), #34495e);
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.brand-header {
  background: linear-gradient(to right, var(--first-color), #34495e);
  color: white;
  padding: 2rem;
}

.brand-subtitle {
  font-size: 1.1rem;
  opacity: 0.9;
  margin-bottom: 1rem;
}

.portal-badge {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: inline-block;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.welcome-text {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--first-color);
}

.custom-input {
  height: 50px;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
  transition: all 0.3s ease;
}

.custom-input:focus {
  border-color: var(--first-color-hover);
  box-shadow: 0 0 0 0.2rem rgba(26, 35, 126, 0.25);
}

.custom-button {
  background: linear-gradient(to right, var(--first-color), #34495e) !important;
  border: none !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  transition: all 0.3s ease !important;
}

.custom-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(13, 71, 161, 0.3);
}

.custom-button:disabled {
  background: linear-gradient(to right, var(--first-color), #34495e);
}

.footer-section {
  background: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.signup-link {
  color: var(--first-color);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signup-link:hover {
  color: var(--first-color-hover);
}

@media (max-width: 768px) {
  .brand-header {
    padding: 1.5rem;
  }
  .portal-badge {
    font-size: 0.8rem;
  }
}
