.privacy-policy-booking {
  margin: clamp(1rem, 2vw, 1.5rem) 0;
  padding: clamp(1rem, 2vw, 1.5rem);
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #e0e0e0;
}

.consent-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
}

.consent-title {
  font-weight: 600;
  color: #2c3e50;
  font-size: clamp(1rem, 1.5vw, 1.1rem);
}

.toggle-icon {
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: #3498db;
  transition: all 0.3s ease;
}

.consent-text {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  font-size: clamp(0.875rem, 1.2vw, 1rem);
  color: #505050;
  line-height: 1.6;
  margin-left: clamp(0.3rem, 1vw, 0.5rem);
}

.consent-text.expanded {
  max-height: 1000px;
  transition: max-height 0.5s ease-in;
}

.consent-text p {
  margin-bottom: clamp(0.5rem, 1vw, 0.75rem);
}

.consent-text a {
  color: #0d47a1;
  text-decoration: none;
}

.consent-text a:hover {
  text-decoration: underline;
}

.form-check-label {
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: block;
}

.form-check-input {
  margin-top: clamp(0.2rem, 0.5vw, 0.3rem);
  width: clamp(16px, 2vw, 18px);
  height: clamp(16px, 2vw, 18px);
  margin-right: clamp(8px, 1.5vw, 12px);
  cursor: pointer;
}

.expand-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.expand-button:hover {
  background-color: rgba(52, 152, 219, 0.1);
}

.consent-text a {
  color: #3498db;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s;
}

.consent-text a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.form-check {
  display: flex;
  align-items: center;
}
